import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
// import CustomerChat from '../components/CustomerChat';
// import '../styles/styles.css';
import '../utilities/AddFontAwesome.js';

const Layout = ({ children }) => {
  return (
    <>
      {/* <CustomerChat /> */}
      <div className="grid-layout">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
