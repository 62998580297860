import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowRight,
  faPhoneAlt,
  faChevronRight,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebook,
  faFacebookMessenger,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowRight,
  faChevronRight,
  faPhoneAlt,
  faFacebook,
  faFacebookMessenger,
  faCheckCircle
);
