import React from 'react';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialMediaItem = ({ icon, userHandle, ariaLabel, link }) => {
  const { primaryColor, primaryHover } = useSiteMetadata();

  return (
    <li className="ml-2 first:ml-0">
      <a
        className="text-2xl focus:outline-none focus:shadow-outline"
        href={`${link}${userHandle}`}
        target="_blank"
        aria-label={ariaLabel}
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={icon}
          className={`${primaryColor} hover:${primaryHover} transition-colors ease-in-out duration-500`}
          fixedWidth
        />
      </a>
    </li>
  );
};

export default SocialMediaItem;
