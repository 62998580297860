export const CallTracking = () => {
  if (window.gtag) {
    window.gtag(
      'send',
      'event',
      'Phone Call Tracking',
      'Click to Call',
      '787-597-6536',
      0
    );
  }
};
