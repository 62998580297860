import React from 'react';

import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatPhoneNumber from '../utilities/FormatPhoneNumber';
import { CallTracking } from '../utilities/EventTracking';

const Phone = () => {
  const {
    phoneNumber,
    primaryBg,
    primaryBgHover,
    primaryColor,
  } = useSiteMetadata();

  return (
    <a
      href={`tel:+1${phoneNumber}`}
      className={`flex items-center justify-center py-4 sm:mb-0 sm:py-0 sm:pr-0 sm:ml-4 lg:ml-6 ${primaryBg} hover:${primaryBgHover} text-white rounded-md sm:bg-transparent sm:hover:bg-transparent sm:${primaryColor} md:cursor-not-allowed focus:outline-none focus:shadow-outline`}
      role="button"
      aria-label={`Nuestro número de teléfono ${phoneNumber}`}
      onClick={CallTracking}
    >
      <FontAwesomeIcon
        icon="phone-alt"
        className="mr-1 current-color sm:-mt-1 md:mt-0"
        fixedWidth
      />
      <p className="mb-0 font-bold text-center uppercase md:block current-color">
        <span className="mr-1 sm:hidden">Llama ahora al </span>
        <span className="sm:hidden md:block">
          {formatPhoneNumber(phoneNumber)}
        </span>
      </p>
    </a>
  );
};

export default Phone;
