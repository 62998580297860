import React from 'react';
import { Link } from 'gatsby';

import { useSiteMetadata } from '../hooks/useSiteMetadata';
import Phone from './Phone';

const Navigation = ({ navState }) => {
  const { primaryColor, menuLinks } = useSiteMetadata();

  return (
    <nav className={`${navState} sm:flex sm:max-h-full sm:opacity-100 md:pt-4`}>
      <div className="hidden">Main Navigation</div>
      <ul className="sm:mt-0 sm:flex sm:flex-row sm:align-center">
        {menuLinks.map((item, index) => {
          return (
            <li
              className="first:border-t border-b last:mb-4 sm:first:border-0 sm:border-0 sm:last:mb-0"
              key={index}
            >
              <Link
                className={`block sm:inline width-full py-4 sm:ml-5 lg:ml-6 text-gray-700 font-semibold hover:${primaryColor} transition-colors duration-300 ease-in-out focus:outline-none focus:shadow-outline `}
                activeClassName={`${primaryColor} font-bold`}
                to={item.link}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
      <Phone />
    </nav>
  );
};

export default Navigation;
