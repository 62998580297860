import React from 'react';
import { Link } from 'gatsby';
import SitePublished from './SitePublished';
import SocialMediaList from './SocialMediaList';
import { CallTracking } from '../utilities/EventTracking';

const Footer = () => {
  return (
    <footer className="bg-stripes">
      <div className="flex flex-col items-center w-full max-w-screen-lg py-8 mx-auto text-center lg:flex-row lg:justify-between lg:content-start">
        <div className="mb-8 text-center lg:order-2 md:mb-8 lg:mb-0 lg:self-start">
          <SocialMediaList />
        </div>
        <div className="lg:order-1">
          <SitePublished /> V&A Roofing, Inc.
          <small className="block text-sm text-center lg:text-left">
            HC-03 Box 12633
            <br />
            Camuy, PR 00627 <br />
            <a
              href="tel:17875976536"
              className="md:cursor-not-allowed"
              onClick={CallTracking}
            >
              Tel. 787-597-6536
            </a>
            <br />
            <div className="mt-2">
              <Link to="/politica-de-privacidad" className="underline">
                Política de privacidad
              </Link>
            </div>
          </small>
        </div>
      </div>
      <p className="text-xs text-center text-gray-500">
        Desarrollado por <a href="https://dcodigo.com">Dcódigo</a>
      </p>
    </footer>
  );
};

export default Footer;
