import React from 'react';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const SitePublished = () => {
  const { sitePublished } = useSiteMetadata();

  const currentDate = new Date().getFullYear();

  const publishedDate = () =>
    sitePublished === currentDate.toString()
      ? `© ${sitePublished}`
      : `© ${sitePublished}-${currentDate}`;

  return <>{publishedDate()}</>;
};

export default SitePublished;
