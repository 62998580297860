import React, { useState } from 'react';
import { Link } from 'gatsby';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import varoofinglogosmall from '../../content/assets/varoofing-logo-small.svg';
import varoofinglogo from '../../content/assets/varoofing-logo.svg';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import Navigation from './Navigation';

const Header = () => {
  const { primaryColor } = useSiteMetadata();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const animation = isOpen ? `max-h-480 block fadein` : `max-h-0 hidden`;
  const headerMargin = isOpen ? 'mb-4' : 'mb-0';

  return (
    <header className="mx-4 mt-2">
      <div className="container py-2 sm:py-6 sm:flex sm:justify-between sm:items-center">
        <div className={`flex justify-between items-center ${headerMargin}`}>
          <div className="py-1 leading-tight">
            <Link
              className="pb-0 focus:outline-none focus:shadow-outline"
              to="/"
            >
              <img
                className="w-12 sm:hidden zh-auto"
                src={varoofinglogosmall}
                alt="V&A Roofing Inc Logo para móviles"
              />
              <img
                className="hidden sm:block sm:h-auto sm:w-56"
                src={varoofinglogo}
                alt="V&A Roofing Inc Logo para móviles"
              />
            </Link>
          </div>
          <div className="sm:hidden">
            <button
              onClick={toggle}
              type="button"
              className={`${primaryColor} mr-2 focus:outline-none focus:shadow-outline`}
              aria-label="Open Menu"
            >
              {!isOpen ? (
                <div className="flex p-3 bg-gray-100 rounded-lg hover:shadow-sm">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </div>
              ) : (
                <div className="flex p-3 bg-gray-100 rounded-lg hover:shadow-sm">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
              )}
            </button>
          </div>
        </div>

        <Navigation navState={animation} />
      </div>
    </header>
  );
};

export default Header;
